<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import Stat from "@/components/widgets/stat";
// import SalesAnalytics from "./sales-analytics";
// import SellingProduct from './selling-product';
// import TopUsers from './top-users';
// import Activity from './activity';
// import SocialSource from './social-source';
import { authFackMethods } from "@/state/helpers";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Stat,
    // SalesAnalytics,
    // SellingProduct,
    // TopUsers,
    // Activity,
    // SocialSource
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Minible",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  created(){
    // this.checkExpiry()
    // this.$notify({
    //   group: 'auth',
    //   title: 'User Authentication',
    //   text: 'Your session has expired, please login again',
    //   type: 'warn'
    // });
  },
  methods:{
    ...authFackMethods,
    /*checkExpiry(){
      let self = this
      let user = JSON.parse(localStorage.getItem('user'));
      let new_date = new Date()
      let timestamp = parseInt((Date.parse(new_date)).toString().slice(0, -3));
      // let counter = 0;
      var id = setInterval(checkExpiredTime, 5000);
      function checkExpiredTime() {
        // if(counter == 3){
        //   console.log('log him out')
        //   clearInterval(id);
        //   self.logout();
        //   self.$router.push({
        //     path: "/login",
        //   });
        // }
        // else{
          if (timestamp > user.Expired_at ) {
            console.log('log him out')
            clearInterval(id);
            self.logout();
            self.$router.push({
              path: "/login",
            });
          } else {
            // counter++;
            console.log(user.Expired_at , timestamp)
            console.log('continue')
          }
        // }
        
      }
    }*/
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <Stat /> -->
    <div class="row">
      <div class="col-md-6 col-xl-3">
          <div class="card">
            <router-link to="/user/list">
              <div class="card-body">
                  User Management
              </div>
            </router-link>
          </div>
      </div>
      <div class="col-md-6 col-xl-3">
          <div class="card">
            <router-link to="/customer/list">
              <div class="card-body">
                  Customer List
              </div>
            </router-link>
          </div>
      </div>
      <div class="col-md-6 col-xl-3">
          <div class="card">
            <router-link to="/loan/list">
              <div class="card-body">
                  Loan
              </div>
            </router-link>
          </div>
      </div>
      <div class="col-md-6 col-xl-3">
          <div class="card">
            <router-link to="/report">
              <div class="card-body">
                  Report
              </div>
            </router-link>
          </div>
      </div>

    </div>

<!--     <div class="row">
      <SalesAnalytics />
       <div class="col-xl-4">
            <div class="card bg-primary">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-sm-8">
                            <p class="text-white font-size-18">
                                Enhance your
                                <b>Campaign</b> for better outreach
                                <i class="mdi mdi-arrow-right"></i>
                            </p>
                            <div class="mt-4">
                                <a href="javascript: void(0);" class="btn btn-success waves-effect waves-light">Upgrade Account!</a>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="mt-4 mt-sm-0">
                                <img src="@/assets/images/setup-analytics-amico.svg" class="img-fluid" alt />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <SellingProduct />
        </div>
    </div>
    <div class="row">
        <TopUsers />
        <Activity />
        <SocialSource />
    </div> -->


  </Layout>
</template>